import React from 'react'
import styled from 'styled-components'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import JotformEmbed from 'react-jotform-embed'

const Contact = () => {
  return (
    <Layout>
      <Seo title='Contact' />
      <StyledContactPage>
        <h1>Contact Us</h1>
        <p>
          Have any questions? Have any feedback such as suggestions on what we can improve on? Feel free to use the form below and talk to us. 
        </p>
        <JotformEmbed src="https://form.jotform.com/210383483704555" />
      </StyledContactPage>
    </Layout>
  )
}

const StyledContactPage = styled.main`
  color: #fff;
  text-align: center;

  h1 {
    font-size: 4rem;
    margin-top: 3rem;
  }

  p {
    font-size: 1.2rem;
    line-height: 1.4;
    max-width: 900px;
    margin: 3rem auto;
    padding: 0 1rem;
  }
`

export default Contact
